/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-base;
  }
}

:root {
  --amplify-primary-color: #4f46e5;
  --amplify-primary-tint: #818cf8;
  --amplify-primary-shade: #818cf8;
}

/* SPINNER CLASSES */
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  /* background-color: #4f46e5; */

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
/* END SPINNER CLASSES */

/* DAY PICKER CLASSES */
.DayPicker-Day {
  border-radius: 0% !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #4f46e5 !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #818cf8 !important;
}
/* END DAY PICKER CLASSES */

/* EDITOR.JS CLASSES */
.codex-editor__redactor {
  margin-right: 0 !important;
  padding-bottom: 50px !important;
}
.tc-table__wrap {
  border: 0.1px solid rgba(22, 22, 22, 0.05) !important;
}
.tc-table__cell {
  border: 0.1px solid rgba(22, 22, 22, 0.05) !important;
}
.tc-table tbody tr td:first-child {
  border-left: none !important;
}
.tc-table tbody tr:first-child td {
  border-top: none !important;
}

.tc-table tbody tr td:last-child {
  border-right: none !important;
}
.tc-table tbody tr:first-child td {
  border-top: none !important;
}
.tc-table tbody tr:last-child td {
  border-bottom: none !important;
}
/* END EDITOR.JS CLASSES */

/* CHAT CLASSES */
.cs-message-list {
  @apply bg-gray-700 border-none !important;
}
.cs-message-input {
  @apply bg-gray-700 border-none !important;
}
.cs-main-container {
  @apply border-none !important;
}
.cs-message-group__footer {
  @apply text-gray-50 opacity-60 !important;
}
.cs-message-group__header {
  @apply text-gray-50 opacity-60 !important;
}
.cs-message-list__loading-more {
  @apply bg-gray-700 border-none !important;
}
/* .cs-message-input__content-editor-wrapper {
  @apply bg-indigo-400 !important;
}
.cs-message-input__content-editor {
  @apply bg-indigo-400 !important;
}

.cs-message__content {
  @apply bg-indigo-400 !important;
}
.cs-message-input__content-editor[data-placeholder]:empty:before {
  @apply bg-indigo-400 !important;
}



.cs-message__sender-name {
  @apply text-gray-50 opacity-70 !important;
}
.cs-message__sent-time {
  @apply text-gray-50 opacity-70 !important;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  @apply text-gray-50 opacity-60 !important;
}
.cs-message__sender-name,
.cs-message__sent-time {
  display: block !important;
}

.cs-message-group--outgoing > .cs-message__html-content {
  background-color: red !important;
} */
/* .cs-message-group--outgoing >  cs-message__content .cs-message__html-content */

/* END CHAT CLASSES */
/* UPPLOAD CLASSES */
.effects-tabs::-webkit-scrollbar {
  display: none !important;
}
.effects-tabs {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
/* END UPPLOAD CLASSES */
